<template>
  <div>
    <nav-bar-back class="detail-nav">
      <div class="title" slot="title">
        <div slot="center">借支列表</div>
      </div>
    </nav-bar-back>

    <div class="scroll-list-wrap">
      <cube-scroll
        ref="scroll"
        :data="borrows"
        :options="options"
        @pulling-down="onPullingDown"
        @pulling-up="onPullingUp"
      >
        <div class="item" v-for="item in borrows" @click="clickItem(item.id)" :key="item.id">
          <!-- {{index}} -->
          <div class="itemTitle" :style="itemStatus(item.status)">
            借支申请
            <p>{{itemDesStatus(item.status)}}</p>
          </div>
          <div class="content">
            <div class="top">
              <p>借支金额</p>
              <p>申请时间</p>
            </div>
            <div class="top">
              <p>100</p>
              <p>2019/11/10</p>
            </div>
          </div>
        </div>
      </cube-scroll>
    </div>
  </div>
</template>

<script>
// 见下方
// getborrows
import { getborrows, getborrowsNext } from "network/apply";
import axios from "axios";
//  var api = require("../axios/api.js");
import NavBarBack from "common/navbar/NavBarBack";

export default {
  components: {
    NavBarBack,
  },
  created() {
    this.loadData();
  },
  computed: {
    itemDesStatus() {
      return function (status) {
        var statusDes;
        switch (status) {
          case -2:
            statusDes = "已取消";
            break;
          case -1:
            statusDes = "申请失败";
            break;
          case 1:
            statusDes = "申请成功";
            break;
          case 0:
            statusDes = "申请中";
            break;
          case 2:
            statusDes = "已放款";
        }
        return statusDes;
      };
    },
    itemStatus() {
      return function (status) {
        var statusColor;
        switch (status) {
          case -2:
            statusColor = { "background-color": "rgb(151,151,151)" };
            break;
          case -1:
            statusColor = { "background-color": "rgb(244,71,30)" };
            break;
          case 1:
            statusColor = { "background-color": "rgb(76,203,42)" };
            break;
          case 0:
            statusColor = { "background-color": "rgb(253,180,10)" };
            break;
          case 2:
            statusColor = { "background-color": "rgb(38,174,255)" };
            break;
        }
        return statusColor;
      };
    },
  },
  data() {
    return {
      status: "申请中",
      msg: [],
      link: [],
      borrows: [],
      // 用来渲染列表的数组
      options: {
        pullDownRefresh: {
          threshold: 90,
          stop: 40,
          txt: "刷新成功",
        }, // 配置下拉刷新
        pullUpLoad: {
          threshold: 0,
          txt: {
            more: "上拉加载更多",
            noMore: "没有更多数据",
          },
        }, // 配置上拉加载，若要关闭可直接 pullUpLoad：false
      },
    };
  },
  methods: {
    //模拟网络数据请求

    getDataWeb() {
      if (this.link.next) {
        getborrowsNext(this.link.next).then((res) => {
          if (res.data) {
            this.borrows = this.borrows.concat(res.data);
          }
          // console.log('数据为空')
          //   this.$refs.scroll.forceUpdate();
          this.link = res.links;
          //  console.log(this.borrows,this.link,res.data,'ggggg')
          if (!this.link) {
            //  console.log('数据为空',this.link)

            this.$refs.scroll.forceUpdate();
          }
        });
        //    console.log('link',this.link.next)
      } else {
        this.$refs.scroll.forceUpdate();
      }
    },
    // 获取数据
    clickItem(id) {
      this.$router.push({ path: "/myBorrowDetail", query: { id: id } });

      // this.$router.push({name:'/myBorrowDetail')
    },
    loadData() {
      this.$refs.scroll.forceUpdate();
      console.log("数据为空2");

      getborrows().then((res) => {
        this.borrows = res.data;
        this.link = res.links;
        // console.log('ress',res)
      });
    },
    // 触发下拉刷新
    onPullingDown() {
      console.log("下拉刷新2222222");

      this.loadData();

      // 模拟更新数据
      //触发下拉刷新我们就重新

      // setTimeout(() => {
      //     if (Math.random() > 0.5) {
      //         // 如果有新数据
      //         console.log('有新数据');
      //         this.msg.unshift(1);
      //     } else {
      //         // 如果没有新数据，调用 forceUpdate(), 显示成功文案，延迟 stopTime 时间完成刷新
      //         this.$refs.scroll.forceUpdate();
      //     }
      // }, 1000)
    },
    // 触发上拉加载
    onPullingUp() {
      // console.log("上拉加载222");
      // 模拟更新数据

      this.getDataWeb();
      // setTimeout(() => {
      //     if (Math.random() > 0.5) {
      //         // 如果有新数据
      //         let newPage = [1,2,3,4,5,6];
      //         console.log('有新数据');
      //         this.msg = this.msg.concat(newPage);
      //     } else {
      //         // 如果没有新数据
      //         this.$refs.scroll.forceUpdate();
      //     }
      // }, 1000);
    },
  },
  mounted() {
    // 挂载
    this.loadData();
  },
};
</script>

<style scoped>
.scroll-list-wrap {
  height: 667px;
}
.itemTitle p {
  color: #fff;
}
.content {
  width: 85%;
  margin: 10px auto 20px auto;
  line-height: 1.8;
  font-size: 14px;
  font-weight: 300;
  /* margin: auto auto */
}

.top {
  display: flex;
  justify-content: space-between;
}
.itemTitle {
  height: 40px;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 16px;
  justify-content: space-between;
  color: #333;
  font-weight: 300;
  align-items: center;
  /* background-color: burlywood; */
  border-radius: 7px 7px 0 0;
}
.item {
  height: 110px;
  margin: 20px;
  border-radius: 7px;
  background-color: white;
}
</style>
